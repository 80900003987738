import React, { useEffect, useState } from 'react';
import { getApiData } from '../../utils/http';
import Card from '../card/Card';
import Subtitle from '../headings/Subtitle';

import SalesChart from './SalesChart';
import * as API_URLS from '../../constants/apiUrls';
import Indicator from '../Indicator';
import { formatNumber } from '../../utils/helper';

const Sales = () => {
  const initialData = {
    isFetching: true,
    error: '',
    data: {},
  };

  const [salesData, setSalesData] = useState(initialData);
  const [salesTypeData, setSalesTypeData] = useState({
    directSales: 0,
    directMonthlyWTrialSales: 0,
    directMonthlyWoTrialSales: 0,
    directYearlyWTrialSales: 0,
    directYearlyWoTrialSales: 0,
    recurringSales: 0,
    recurringMonthlySales: 0,
    recurringYearlySales: 0,
  });

  useEffect(() => {
    (async () => {
      const data = Object.assign({}, salesData, initialData);

      try {
        const response = await getApiData({
          endPoint: API_URLS.SALES_URL,
        });

        data.data = response.data;
      } catch (e) {
        data.error = e.message;
      } finally {
        data.isFetching = false;
      }

      setSalesData(data);
    })();
  }, []);

  useEffect(() => {
    if (salesData.data && Object.keys(salesData.data).length) {
      const directSalesCountByPlanName = salesData?.data?.directSalesCountByPlanName;
      const directSales = salesData?.data?.directAndRecurringPurchaseCountOfLast30Days[0]?.first_time_purchase;

      const directMonthlyWTrialSales = directSalesCountByPlanName?.find(
        (s) => s.plan_name === 'PRO Monthly - 7days FREE Trial'
      )?.direct_sales_count;
      const directMonthlyWoTrialSales = directSalesCountByPlanName?.find(
        (s) => s.plan_name === 'PRO Monthly'
      )?.direct_sales_count;
      const directYearlyWTrialSales = directSalesCountByPlanName?.find(
        (s) => s.plan_name === 'PRO Annual - 7days FREE Trial'
      )?.direct_sales_count;
      const directYearlyWoTrialSales = directSalesCountByPlanName?.find(
        (s) => s.plan_name === 'PRO Annual'
      )?.direct_sales_count;

      const recurringSales = salesData?.data?.directAndRecurringPurchaseCountOfLast30Days[0]?.recurring_purchase;
      const recurringSalesCountByPlanName = salesData?.data?.recurringSalesCountByPlanName;

      const recurringMonthlySalesFilter = recurringSalesCountByPlanName?.filter(
        (s) => s.plan_name === 'PRO Monthly - 7days FREE Trial' || s.plan_name === 'PRO Monthly'
      );

      const recurringYearlySalesFilter = recurringSalesCountByPlanName?.filter(
        (s) => s.plan_name === 'PRO Annual - 7days FREE Trial' || s.plan_name === 'PRO Annual'
      );

      const recurringMonthlySales =
        recurringMonthlySalesFilter.length > 1
          ? recurringMonthlySalesFilter.reduce((current, past) => current.direct_sales_count + past.direct_sales_count)
          : recurringMonthlySalesFilter[0].direct_sales_count || 0;
      const recurringYearlySales =
        recurringYearlySalesFilter.length > 1
          ? recurringYearlySalesFilter.reduce((current, past) => current.direct_sales_count + past.direct_sales_count)
          : recurringYearlySalesFilter[0].direct_sales_count || 0;

      const salesOtherData = {
        directSales,
        directMonthlyWTrialSales,
        directMonthlyWoTrialSales,
        directYearlyWTrialSales,
        directYearlyWoTrialSales,
        recurringSales,
        recurringMonthlySales,
        recurringYearlySales,
      };

      setSalesTypeData(salesOtherData);
    }
  }, [salesData]);

  const getSalesPercentage = () => {
    const currentData = salesData?.data?.last30Days?.length;
    const pastData = salesData?.data?.lastPast30Days?.length;

    if (!currentData || !pastData) {
      return 0;
    }

    const percentage = (((currentData - pastData) / pastData) * 100).toFixed(0);

    return percentage;
  };

  const getTrialToPaymentPercentages = () => {
    if (!salesData?.data?.trialToPayment) {
      return {
        trialToPaymentPercentage: 0,
        trialToPaymentRefPercentage: 0,
        diffPercentage: 0,
      };
    }

    const trialData = salesData?.data?.trialToPayment[0];
    const trialDataRef = salesData?.data?.trialToPaymentReference[0];

    return {
      trialToPaymentPercentage: trialData.trial_to_purchase_rate,
      trialToPaymentRefPercentage: trialDataRef.trial_to_purchase_rate,
      diffPercentage: trialData.trial_to_purchase_rate - trialDataRef.trial_to_purchase_rate,
    };

    // const trialToPaymentPercentage = (
    //   (trialData.trial_to_payment / trialData.total_trials) *
    //   100
    // ).toFixed(2);
    // const trialToPaymentRefPercentage = (
    //   (trialDataRef.trial_to_payment / trialDataRef.total_trials) *
    //   100
    // ).toFixed(2);

    // const diffPercentage = (
    //   trialToPaymentPercentage - trialToPaymentRefPercentage
    // ).toFixed(2);

    // return {
    //   trialToPaymentPercentage,
    //   trialToPaymentRefPercentage,
    //   diffPercentage,
    // };
  };

  const renderSalesStatus = (
    <div className="chart-summary">
      <span className="chart-summary__caption">Total Units Sold</span>
      <div className="chart-summary__ratio">
        <div className="chart-summary__count">{formatNumber(salesData?.data?.last30Days?.length) ?? 'N/A'}</div>
        {/* <div className="chart-summary__percent">
          <Indicator
            number={getSalesPercentage()}
            text="vs last 30 days"
            isPositive={getSalesPercentage() >= 0}
          />
        </div> */}
      </div>
    </div>
  );

  const renderSalesChart = (
    <div className="sales__chart">
      <SalesChart
        currentData={salesData?.data?.last30Days}
        pastData={salesData?.data?.lastPast30Days}
        directAndRecurringPurchaseCountOfLast30Days={salesData?.data?.directAndRecurringPurchaseCountOfLast30Days}
      />
    </div>
  );

  const renderDirectSales = (
    <div className="sales__direct">
      <div className="sales__direct-caption">
        <Subtitle>Direct sales: {salesTypeData.directSales}</Subtitle>
      </div>

      <div className="progress-caption sales-progress">
        <div className="progress-caption__label-left">Monthly {salesTypeData.directMonthlyWoTrialSales ?? 'N/A'}</div>
        <div className="progress-caption__label-right">
          Monthly w/ trial {salesTypeData.directMonthlyWTrialSales ?? 'N/A'}
        </div>
      </div>

      <div className="progress">
        <div
          className="progress-bar monthly"
          role="progressbar"
          style={{
            width: `${(
              (salesTypeData.directMonthlyWoTrialSales /
                (salesTypeData.directMonthlyWoTrialSales + salesTypeData.directMonthlyWTrialSales)) *
              100
            ).toFixed(0)}%`,
          }}
        ></div>
        <div
          className="progress-bar monthly-trial"
          role="progressbar"
          style={{
            width: `${(
              (salesTypeData.directMonthlyWTrialSales /
                (salesTypeData.directMonthlyWoTrialSales + salesTypeData.directMonthlyWTrialSales)) *
              100
            ).toFixed(0)}%`,
          }}
        ></div>
      </div>

      <div className="progress-caption sales-progress">
        <div className="progress-caption__label-left">Yearly {salesTypeData.directYearlyWoTrialSales}</div>
        <div className="progress-caption__label-right">Yearly w/ trial {salesTypeData.directYearlyWTrialSales}</div>
      </div>

      <div className="progress">
        <div
          className="progress-bar yearly"
          role="progressbar"
          style={{
            width: `${(
              (salesTypeData.directYearlyWoTrialSales /
                (salesTypeData.directYearlyWoTrialSales + salesTypeData.directYearlyWTrialSales)) *
              100
            ).toFixed(0)}%`,
          }}
        ></div>
        <div
          className="progress-bar yearly-trial"
          role="progressbar"
          style={{
            width: `${(
              (salesTypeData.directYearlyWTrialSales /
                (salesTypeData.directYearlyWoTrialSales + salesTypeData.directYearlyWTrialSales)) *
              100
            ).toFixed(0)}%`,
          }}
        ></div>
      </div>
    </div>
  );

  const renderRecurringSales = (
    <div className="sales__direct">
      <div className="sales__direct-caption">
        <Subtitle>Recurring sales: {salesTypeData.recurringSales}</Subtitle>
      </div>

      <div className="progress-caption sales-progress">
        <div className="progress-caption__label-left">Monthly {salesTypeData.recurringMonthlySales}</div>
        <div className="progress-caption__label-right">Yearly {salesTypeData.recurringYearlySales}</div>
      </div>

      <div className="progress">
        <div
          className="progress-bar monthly-trial"
          role="progressbar"
          style={{
            width: `${((salesTypeData.recurringMonthlySales / salesTypeData.recurringSales) * 100).toFixed(0)}%`,
          }}
        ></div>
        <div
          className="progress-bar yearly-trial"
          role="progressbar"
          style={{
            width: `${((salesTypeData.recurringYearlySales / salesTypeData.recurringSales) * 100).toFixed(0)}%`,
          }}
        ></div>
      </div>
    </div>
  );

  const renderTrialToPaymentRate = (
    <div className="sales__trial">
      <div className="sales__trial-caption">
        <Subtitle>Trial To Payment Rate:</Subtitle>
      </div>
      <div className="sales__trial-ratio">
        <div className="sales__trial-percent sales__trial-percent--primary">
          {getTrialToPaymentPercentages().trialToPaymentPercentage}%
        </div>
        <div className="sales__trial-status">
          <Indicator
            number={getTrialToPaymentPercentages().diffPercentage}
            text="vs last 30 days"
            isPositive={getTrialToPaymentPercentages().diffPercentage >= 0}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className="sales dashboard-fix-child">
      {salesData.isFetching ? (
        <div>Loading...</div>
      ) : (
        <Card title="Sales" caption="vs 30 days ago">
          {renderSalesStatus}
          {renderSalesChart}
          {renderDirectSales}
          {renderRecurringSales}
          {renderTrialToPaymentRate}
        </Card>
      )}
    </div>
  );
};

export default Sales;
