import { createContext } from 'react';

// Initial State for Global Context
const initialState = {
  days: 1,
  setDays: day => {},
};

const GlobalContext = createContext(initialState);

export default GlobalContext;
