import React from "react";
import * as dateHelper from "date-fns";
import { Line } from "react-chartjs-2";

import Subtitle from "../headings/Subtitle";

const SalesChart = ({
  currentData,
  pastData,
  directAndRecurringPurchaseCountOfLast30Days,
}) => {
  if (
    !currentData ||
    !pastData ||
    currentData.length === 0 ||
    pastData.length === 0
  ) {
    return <div>Chart data not available</div>;
  }

  const last30DaysChartData = currentData.map((s) => s.number_of_purchases);
  const lastPast30DaysChartData = pastData.map((s) => s.number_of_purchases);

  const labels = currentData.map((d, i) =>
    dateHelper.format(new Date(d.date), "MMM d")
  );

  const options = {
    responsive: true,
    aspectRatio: 3.75,
    scales: {
      x: {
        ticks: {
          callback: function (val, index) {
            // Hide the label of every 2nd dataset
            return index % 4 === 0 ? this.getLabelForValue(val) : "";
          },
          color: "#25265E",
        },
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          drawTicks: false,
        },
        ticks: {
          padding: 8,
          color: "#25265E",
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: "Preceding Period",
        align: "end",
        data: lastPast30DaysChartData,
        borderColor: "#6501e5",
        borderWidth: 1,
        borderDash: [3, 2],
        pointRadius: 0,
      },
      {
        label: "Last 30 Days",
        data: last30DaysChartData,
        borderColor: "#6501E5",
        borderWidth: 1,
        pointRadius: 0,
      },
    ],
  };

  return (
    <div className="sales-chart">
      <div className="sales-chart__caption">
        <Subtitle>
          Total Sales = Direct (
          {directAndRecurringPurchaseCountOfLast30Days[0]?.first_time_purchase})
          + Recurring (
          {directAndRecurringPurchaseCountOfLast30Days[0]?.recurring_purchase})
        </Subtitle>
      </div>

      <Line data={data} options={options} />
    </div>
  );
};

export default SalesChart;
