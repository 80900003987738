export function formatNumber(number, locale = "en-US") {
  if (isNaN(number)) {
    return number;
  }

  return new Intl.NumberFormat(locale).format(number);
}

export function formatCurrency(amount, locale = "en-US") {
  if (isNaN(amount)) {
    return amount;
  }

  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "USD",
  }).format(amount);
}
