import React from 'react';
import Heading3 from '../headings/Heading3';
import Subtitle from '../headings/Subtitle';

const Card = ({ children, title, caption, footer }) => {
  return (
    <div className="card">
      <div className="card__header">
        <div className="card__header-title">
          <Heading3 variant="primary">{title}</Heading3>
        </div>
        <div className="card__subtitle d-md-none">
          <Subtitle>{caption}</Subtitle>
        </div>
      </div>

      <div className="card__body">{children}</div>
      {footer && footer}
    </div>
  );
};

export default Card;
