import React, { useState } from "react";

import Header from "../components/header/Header";
import Statistics from "../components/statistics/Statistics";
import SubHeader from "../components/subheader/SubHeader";
import GlobalContext from "../contexts/GlobalProvider";

const Main = () => {
  const [days, setDays] = useState(1);

  return (
    <GlobalContext.Provider value={{ days, setDays }}>
      <Header />
      <SubHeader />
      <Statistics />
    </GlobalContext.Provider>
  );
};

export default Main;
