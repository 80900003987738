import React from "react";
import Sales from "../sales/Sales";
import CustomerSupport from "../support/CustomerSupport";
import Traffic from "../traffic/Traffic";
import UserAcquisition from "../user-acquisition/UserAcquisiton";

const Statistics = () => {
  return (
    <div className="statistics container dashboard-fix">
      <UserAcquisition />
      <Sales />
      {/* <CustomerSupport /> */}
      {/* <Traffic /> */}
    </div>
  );
};

export default Statistics;
