import React from 'react';

const Heading2 = ({ children, variant }) => {
  const classname = `heading-2 heading-2--${variant}`;

  return <h2 className={classname}>{children}</h2>;
};

Heading2.defaultProps = {
  variant: 'dark',
};

export default Heading2;
