import React, { useContext, useEffect } from "react";
import GlobalContext from "../../contexts/GlobalProvider";
import logo from "../../logo.svg";
import { mapDaysToServer } from "../../utils";

const renderServerStatus = (count) => {
  const el = [];

  for (let i = 0; i < count; i++) {
    el.push(<div className="bg-danger" />);
  }

  return <div className="server-status">{el}</div>;
  // return (
  //   <div className="server-status">
  //     <div className="bg-danger" />
  //     <div className="bg-success" />
  //     <div className="bg-danger--medium" />
  //     <div className="bg-danger--low" />
  //   </div>
  // );
};

const Header = () => {
  const { days } = useContext(GlobalContext);
  let count = mapDaysToServer(days);

  useEffect(() => {
    count = mapDaysToServer(days);
  }, [days]);

  return (
    <header className="header">
      <div className="container">
        <a
          className="header__branding"
          href="https://programiz.pro"
          target="_blank"
          rel="noreferrer"
        >
          <div className="header__branding-logo">
            <img className="header__branding-image" src={logo} alt="logo" />
          </div>
          <h1 className="header__branding-text">Programiz PRO Dashboard</h1>
          {/* {renderServerStatus(count)} */}
        </a>
      </div>
    </header>
  );
};

export default Header;
