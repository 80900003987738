import React from "react";

const Indicator = ({ number, text, isPositive = true }) => {
  const label = number === 0 ? text.replace("vs", "Same as") : text;
  const shownNumber = number !== 0 ? `${Math.abs(number)}%` : "";

  return (
    <>
      {number > 0 ? (
        <svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.71267 1.60817L9.24184 7.49984C9.38991 7.75626 9.39079 8.07199 9.24416 8.32923C9.09754 8.58647 8.82542 8.74659 8.52934 8.74984L1.47101 8.74984C1.17493 8.74659 0.902814 8.58647 0.756185 8.32923C0.609558 8.07199 0.610442 7.75626 0.758507 7.49984L4.28767 1.60818C4.43874 1.35913 4.70889 1.20703 5.00017 1.20703C5.29146 1.20703 5.56161 1.35913 5.71267 1.60817Z"
            fill="#56BD5B"
            stroke="#56BD5B"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : number < 0 ? (
        <svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.28733 8.39183L0.758159 2.50016C0.610093 2.24374 0.609209 1.92801 0.755837 1.67077C0.902464 1.41353 1.17458 1.25341 1.47066 1.25016H8.52899C8.82507 1.25341 9.09719 1.41353 9.24382 1.67077C9.39044 1.92801 9.38956 2.24374 9.24149 2.50016L5.71233 8.39183C5.56126 8.64087 5.29111 8.79297 4.99983 8.79297C4.70854 8.79297 4.43839 8.64087 4.28733 8.39183Z"
            fill="#E34C4C"
            stroke="#E34C4C"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : null}
      <span
        className={`status-percent ${
          isPositive ? "status-percent--success" : "status-percent--danger"
        } `}
      >
        {shownNumber} {label}
      </span>
    </>
  );
};

export default Indicator;
