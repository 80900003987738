import React from 'react';

const Heading3 = ({ children, variant }) => {
  const classname = `heading-3 heading-3--${variant}`;

  return <h3 className={classname}>{children}</h3>;
};

Heading3.defaultProps = {
  variant: 'dark',
};

export default Heading3;
