import React from "react";
import Cookies from "universal-cookie";
import { Navigate, useNavigate } from "react-router-dom";
import { GoogleLogin } from "react-google-login";

import { COOKIE_GOOGLE_LOGIN } from "../constants/cookies";

const Login = () => {
  const navigate = useNavigate();
  const cookies = new Cookies();

  const isUserLoggedIn = cookies.get(COOKIE_GOOGLE_LOGIN)?.length > 0;

  if (isUserLoggedIn) {
    return <Navigate to="/" replace />;
  }

  const handleGoogleSuccess = (response) => {
    if (!!response.tokenId) {
      // store the token in a cookie
      cookies.set(COOKIE_GOOGLE_LOGIN, response.tokenId, { path: "/" });

      // Redirect to the dashboard
      navigate("/");
    }
  };

  const handleGoogleError = (response) => {
    cookies.remove(COOKIE_GOOGLE_LOGIN);

    console.log("error", response);
  };

  return (
    <div className="login-wrapper">
      <h1 className="login-title">Welcome to Programiz PRO Dashboard</h1>
      <GoogleLogin
        clientId="345958353815-oc527pe1lfqjug4u479but53i072oakt.apps.googleusercontent.com"
        buttonText="Continue with Google"
        onSuccess={handleGoogleSuccess}
        onFailure={handleGoogleError}
        cookiePolicy={"single_host_origin"}
      />
      {/* <div class="google-signin">
        <span class="google-signin__google-logo">
          <img src="images/google.png" alt="login with google" />
        </span>
        <span class="google-signin__subtitle-text">Continue with Google</span>
      </div> */}

      {/* <hr /> */}

      {/* <form action="#">
    <div>
      <label for="email">Email</label>
        <input class="form-control" type="text" placeholder="Write your email id here" />
        <span class="form-error">
          Invalid email id
        </span>
    </div>
    <div>
      <label for="password">Password</label>
      <input class="form-control" type="password" placeholder="Write your password here" />
    </div>

    <div>
      <div class="form-container">
        <div>
          <input type="checkbox" name="remember" id="remember" />
          <label for="remember">Remember me</label>
        </div>
        <div>
          <a href="#">Forgot password?</a>
        </div>
      </div>
    </div>

    <div>
      <button type="submit">Sign in</button>
    </div>

  </form> */}
    </div>
  );
};

export default Login;
