// render server status according to days
export const mapDaysToServer = numOfDays => {
  switch (numOfDays) {
    case 1:
      return 24;
    case 7:
      return 7;
    case 30:
      return 30;
    case 365:
      return 12;
    default:
      return 24;
  }
};
