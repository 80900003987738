import React, { useEffect, useState } from "react";

import UserChart from "../user-acquisition/UserChart";
import Card from "../card/Card";
import Subtitle from "../headings/Subtitle";
import { getApiData } from "../../utils/http";
import * as API_URLS from "../../constants/apiUrls";
import Indicator from "../Indicator";
import { formatNumber } from "../../utils/helper";

const UserAcquisition = () => {
  const initialData = {
    isFetching: true,
    error: "",
    data: [],
  };

  const [userAcquisitionData, setUserAcquisitionData] = useState(initialData);

  useEffect(() => {
    (async () => {
      const data = Object.assign({}, userAcquisitionData, initialData);

      try {
        const response = await getApiData({
          endPoint: API_URLS.USER_ACQUISITION_URL,
        });

        data.data = response.data;
      } catch (e) {
        data.error = e.message;
      } finally {
        data.isFetching = false;
      }

      setUserAcquisitionData(data);
    })();
  }, []);

  const getUserSignedUpPercentage = () => {
    const pastData =
      userAcquisitionData?.data?.signUpsData?.thirty_days_reference;
    const newData =
      userAcquisitionData?.data?.signUpsData?.thirty_days_indicator;

    if (!pastData || !newData) {
      return 0;
    }

    const percentage = (((newData - pastData) / pastData) * 100).toFixed(2);

    return percentage;
  };

  const getUserVisitsPercentage = () => {
    if (!userAcquisitionData?.data?.userVisitsData) {
      return 0;
    }

    const pastCount = userAcquisitionData?.data?.userVisitsData[0]?.reference;
    const todayCount = userAcquisitionData?.data?.userVisitsData[0]?.indicator;

    return (((todayCount - pastCount) / pastCount) * 100).toFixed(2);
  };

  const getVisitToSignUpRate = () => {
    if (!userAcquisitionData?.data?.userVisitsData) {
      return 0;
    }

    const pastVisitCount =
      userAcquisitionData?.data?.userVisitsData[0]?.reference;
    const pastSignUpCount =
      userAcquisitionData?.data?.signUpsData?.thirty_days_reference;

    const pastPercentage = ((pastSignUpCount / pastVisitCount) * 100).toFixed(
      2
    );

    const todayVisitCount =
      userAcquisitionData?.data?.userVisitsData[0]?.indicator;
    const todaySignUpCount =
      userAcquisitionData?.data?.signUpsData?.thirty_days_indicator;

    const todayPercentage = (
      (todaySignUpCount / todayVisitCount) *
      100
    ).toFixed(2);

    return {
      todayPercentage,
      diff: (todayPercentage - pastPercentage).toFixed(2),
    };
  };

  const renderUserStatus = userAcquisitionData?.data?.userVisitsData ? (
    <div className="chart-summary chart-summary--flex">
      <div className="chart-summary__visits">
        <span className="chart-summary__caption">User visits</span>
        <div className="chart-summary__count">
          {formatNumber(userAcquisitionData?.data?.userVisitsData[0]?.indicator)}
        </div>
        <div className="chart-summary__percent">
          <Indicator
            number={getUserVisitsPercentage()}
            text="vs last 30 days"
            isPositive={getUserVisitsPercentage() >= 0}
          />
        </div>
      </div>
      <div className="chart-summary__signed">
        <span className="chart-summary__caption">User signed up</span>
        <div className="chart-summary__count">
          {formatNumber(
            userAcquisitionData?.data?.signUpsData?.thirty_days_indicator
          ) ?? "N/A"}
        </div>
        <div className="chart-summary__percent">
          <Indicator
            number={getUserSignedUpPercentage()}
            text="vs last 30 days"
            isPositive={getUserVisitsPercentage() >= 0}
          />
        </div>
      </div>
    </div>
  ) : null;

  const renderUserRatio = (
    <div className="user__ratio">
      <span className="user__ratio-caption">Visits to Signed up Rate</span>
      <div className="user__ratio-percent">
        <div className="user__ratio-percent-main">
          {getVisitToSignUpRate().todayPercentage}%
        </div>
        <div className="user__ratio-percent-sub">
          <Indicator
            number={getVisitToSignUpRate().diff}
            text="vs last 30 days"
            isPositive={getVisitToSignUpRate().diff >= 0}
          />
        </div>
      </div>
    </div>
  );

  const renderUserChart = (
    <div className="user__chart">
      <UserChart
        currentData={userAcquisitionData?.data?.signUpData}
        pastData={userAcquisitionData?.data?.signUpPastData}
      />
    </div>
  );

  const renderFooter = (
    <div className="card__footer">
      <Subtitle>Lifetime User Count</Subtitle>
      <div className="card__footer-main">
        {formatNumber(userAcquisitionData?.data?.signUpsData?.life_time_users) ?? "N/A"}
      </div>
    </div>
  );

  return (
    <div className="user dashboard-fix-child">
      {userAcquisitionData.isFetching ? (
        <div>Loading...</div>
      ) : (
        <Card
          title="User Acquisition"
          caption="vs 30 days ago"
          footer={renderFooter}
        >
          <div className="user__info">
            {renderUserStatus}
            {renderUserRatio}
          </div>
          {renderUserChart}
        </Card>
      )}
    </div>
  );
};

export default UserAcquisition;
