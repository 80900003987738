import React, { useContext, useState } from "react";
import { useEffect } from "react";
import GlobalContext from "../../contexts/GlobalProvider";
import { getApiData } from "../../utils/http";
import Heading2 from "../headings/Heading2";
import Subtitle from "../headings/Subtitle";
import * as API_URLS from "../../constants/apiUrls";
import * as dateHelper from "date-fns";
import { FiCalendar } from "react-icons/fi";

const options = [
  {
    label: "Last 1 Day",
    value: "1",
  },
  {
    label: "Last 7 Days",
    value: "7",
  },
  {
    label: "Last 30 Days",
    value: "30",
  },
  {
    label: "Last 1 Year",
    value: "365",
  },
];

const SubHeader = () => {
  const { days, setDays } = useContext(GlobalContext);

  const initialData = {
    isFetching: true,
    error: "",
    data: null,
  };

  const [otherData, setOthersData] = useState(initialData);

  useEffect(() => {
    (async () => {
      const data = Object.assign({}, otherData, initialData);

      try {
        const response = await getApiData({
          endPoint: API_URLS.OTHERS_URL,
        });

        data.data = response.data;
      } catch (e) {
        data.error = e.message;
      } finally {
        data.isFetching = false;
      }

      setOthersData(data);
    })();
  }, []);

  const handleDaysChange = (day) => {
    setDays(Number(day));
  };

  const getLastUpdatedDate = (updatedDate) => {
    const currentTime = new Date(Date.now());
    const lastUpdatedTime = new Date(updatedDate);

    const yearDiff = dateHelper.differenceInYears(currentTime, lastUpdatedTime);
    const monthDiff =
      dateHelper.differenceInMonths(currentTime, lastUpdatedTime) % 12;
    const dayDiff = dateHelper.differenceInDays(currentTime, lastUpdatedTime);
    const hourDiff =
      dateHelper.differenceInHours(currentTime, lastUpdatedTime) % 24;
    const minuteDiff =
      dateHelper.differenceInMinutes(currentTime, lastUpdatedTime) % 60;

    let text = "";

    if (yearDiff) {
      text += `${yearDiff} year${yearDiff > 1 ? "s" : ""} `;
    }

    if (monthDiff) {
      text += `${monthDiff} month${monthDiff > 1 ? "s" : ""} `;
    }

    if (dayDiff) {
      text += `${dayDiff} day${dayDiff > 1 ? "s" : ""} `;
    }

    if (!yearDiff && !monthDiff && !dayDiff) {
      if (hourDiff) {
        text += `${hourDiff} hour${hourDiff > 1 ? "s" : ""} `;
      }

      if (minuteDiff) {
        text += `${minuteDiff} minute${minuteDiff > 1 ? "s" : ""} `;
      }
    }

    return text + "ago";
  };

  return (
    <div className="sub-header">
      <div className="container sub-header__wrapper">
        <div className="sub-header__title">
          <Heading2>Our KPIs</Heading2>
          <Subtitle>
            Last updated{" "}
            {otherData.isFetching
              ? "loading..."
              : getLastUpdatedDate(otherData.data.lastUpdatedDate)}
          </Subtitle>
        </div>
        <label className="sub-header__input">
          <span className="select form-control">
            <FiCalendar size={16} />
            <span className="sub-header__input__text">Last 30 days</span>
          </span>
          {/* <select
            value={days}
            className="select form-control"
            onChange={e => handleDaysChange(e.target.value)}
          >
            {options.map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select> */}
        </label>
      </div>
    </div>
  );
};

export default SubHeader;
