import axios from "axios";

import * as API_URLS from "../constants/apiUrls";

/**
 * Get response data from API.
 *
 * @param  method = GET, POST, DELETE, PUT (default GET)
 * @param  endPoint = API url
 * @param  queryParams = parameters
 * @param  data for post
 * @param  additionalHeaders
 * @returns
 */
export async function getApiData({
  method = "GET",
  endPoint,
  queryParams = {},
  data = {},
  additionalHeaders = {},
}) {
  try {
    let headers = Object.assign(
      {
        "Content-Type": "application/json",
      },
      additionalHeaders
    );

    const response = await axios({
      baseURL: API_URLS.BASE_URL,
      method,
      url: endPoint,
      data,
      params: queryParams,
      headers,
      timeout: 30000,
    });

    return response.data;
  } catch (error) {
    if (error?.response) {
      throw new Error(JSON.stringify(error?.response?.data));
    } else if (error.request) {
      throw new Error("networkError");
    } else {
      throw new Error("offlineError");
    }
  }
}
