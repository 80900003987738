import React from "react";
import * as dateHelper from "date-fns";
import { Line } from "react-chartjs-2";

import images from "../../assets/images";
import Subtitle from "../headings/Subtitle";

const UserChart = ({ currentData, pastData }) => {
  if (
    !currentData ||
    !pastData ||
    currentData.length === 0 ||
    pastData.length === 0
  ) {
    return <div>Chart data not available</div>;
  }

  const labels = currentData.map((d) =>
    dateHelper.format(new Date(d.CreatedAt), "MMM d")
  );

  const currentMonthChartData = currentData.map((d) => d.number_of_signups);
  const pastMonthChartData = pastData.map((d) => d.number_of_signups);

  const options = {
    responsive: true,
    aspectRatio: 2,

    scales: {
      x: {
        display: true,
        ticks: {
          callback: function (val, index) {
            // Hide the label of every 2nd dataset
            return index % 4 === 0 ? this.getLabelForValue(val) : "";
          },
          color: "#25265E",
        },
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          drawTicks: false,
        },
        ticks: {
          padding: 8,
          color: "#25265E",
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        align: "end",
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: "Preceeding Period",
        align: "end",
        data: pastMonthChartData,
        borderColor: "#6501e5",
        borderWidth: 1,
        borderDash: [3, 2],
        pointRadius: 0,
      },
      {
        label: "Last 30 Days",
        data: currentMonthChartData,
        borderColor: "#6501E5",
        borderWidth: 1,
        pointRadius: 0,
      },
    ],
  };

  return (
    <div className="user-chart">
      <div className="user-chart__caption">
        <Subtitle>Signed up:</Subtitle>
      </div>
      <div>
        <div className="chart-legends">
          <span className="chart-legends__item">
            Preceeding period{" "}
            <img
              className="chart-legends__item__image"
              src={images.legendDotted}
              alt="preceeding period"
            />
          </span>
          <span className="chart-legends__item">
            Last 30 days{" "}
            <img
              className="chart-legends__item__image"
              src={images.legendSolid}
              alt="last 30 days"
            />
          </span>
        </div>
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default UserChart;
