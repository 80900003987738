import Cookies from "universal-cookie";
import { COOKIE_GOOGLE_LOGIN } from "./constants/cookies";
import { Route, Routes, BrowserRouter } from "react-router-dom";

import Main from "./pages/Main";
import Login from "./pages/Login";
import ProtectedRoute from "./routes/ProtectedRoute";

const Router = () => {
  const cookies = new Cookies();
  const isUserLoggedIn = cookies.get(COOKIE_GOOGLE_LOGIN)?.length > 0;

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute isAllowed={isUserLoggedIn}>
              <Main />
            </ProtectedRoute>
          }
        />
        <Route path="login" element={<Login />} />
        <Route path="*" element={<Main />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
